import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Button, Container, Header } from "semantic-ui-react"

import { StyledContainer } from "../components/core"
import Layout from "../components/layout-desktop"
import SEO from "../components/seo"
import renderAst from "../components/render-markdown"

const whySixEyePage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/why/why-sixeye/" }
      ) {
        htmlAst
        frontmatter {
          title
          subtitle
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Why SixEye?"
        description="SixEye combines the management and control of products from multiple manufacturers into one portal, accessible in your web browser. What's more, this portal will carry your own branding and is available at your own Internet domain."
      />
      <Header as="h1" textAlign="center">
        {markdownData.frontmatter.title}
        <Header.Subheader>{markdownData.frontmatter.subtitle}</Header.Subheader>
      </Header>
      <StyledContainer text>{renderAst(markdownData.htmlAst)}</StyledContainer>
      <Container text textAlign="center">
        <Link to="/getting-started/">
          <Button primary size="big">
            Get Started
          </Button>
        </Link>
      </Container>
    </Layout>
  )
}
export default whySixEyePage;
